import { HTTP } from '../../../http-common'
import helper from '../index'

export default {
  getVotersGuides: async (data, electionId) => {
    const response = await HTTP.get(
      '/elections/' + electionId + '/voters-guides',
      {
        params: data,
        headers: helper.setHeaders()
      }
    )
    return response.data
  },
  getVoterGuidesLegislation: async (data, electionId) => {
    const response = await HTTP.get(
      '/elections/' + electionId + '/voter-guide-legislations',
      {
        params: data,
        headers: helper.setHeaders()
      }
    )
    return response.data
  },
  getStates: async () => {
    const response = await HTTP.get('/states', {
      headers: helper.setHeaders()
    })
    return response.data
  },
  getCounties: async () => {
    const response = await HTTP.get('/counties', {
      headers: helper.setHeaders()
    })
    return response.data
  },
  getCities: async () => {
    const response = await HTTP.get('/cities', {
      headers: helper.setHeaders()
    })
    return response.data
  },
  getDivisionType: async (scope) => {
    const response = await HTTP.get('/zone-types/scope/' + scope, {
      headers: helper.setHeaders()
    })
    return response.data
  },
  getCodeValue: async (divisionId) => {
    const response = await HTTP.get('zone-types/' + divisionId + '/zones', {
      headers: helper.setHeaders()
    })
    return response.data
  },
  addVotersGuide: async (voterGuides) => {
    await HTTP.post('/voters-guides', voterGuides, {
      headers: helper.setHeaders()
    })
  },
  addVoterLegislationGuide: async (voterGuides) => {
    await HTTP.post('/voter-guide-legislation', voterGuides, {
      headers: helper.setHeaders()
    })
  },
  addMultipleVotersGuide: async (voterGuides) => {
    await HTTP.post('/voters-guides/create-multiple/', voterGuides, {
      headers: helper.setHeaders()
    })
  },
  editVotersGuide: async (data, id) => {
    await HTTP.put(
      '/voters-guides/admin/edit/' + id,
      { data },
      {
        headers: helper.setHeaders()
      }
    )
  },
  editVoterLegislationGuide: async (data, id) => {
    await HTTP.put(
      '/voter-guide-legislation/' + id,
      { data },
      {
        headers: helper.setHeaders()
      }
    )
  },
  getGuidesDetail: async (electionId, zoneId) => {
    const response = await HTTP.get(`elections/${ electionId }/voters-guides/${ zoneId }/detail`, {
      headers: helper.setHeaders()
    })
    return response.data
  },
  async deleteVotersGuide(ids) {
    await HTTP.put(
      'voters-guides/delete-multiple', ids, {
        headers: helper.setHeaders()
      }
    )
  },
  async deleteVotersGuideDrafts(ids) {
    await HTTP.put(
      'voters-guides-drafts/delete-multiple', ids, {
        headers: helper.setHeaders()
      }
    )
  },
  async publishGuides(ids) {
    return await HTTP.post('voters-guides/publish', ids, {
      headers: helper.setHeaders()
    })
  }
}
