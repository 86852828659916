<template>
  <div v-frag>
    <v-alert
      v-model='showAlert'
      class='rounded-xl pr-10'
      color='green'
      dark
      dense
      dismissible
      icon='mdi-information-outline'
      prominent
      transition='scale-transition'
      width='100%'
    >
      <p class='font-weight-bold pt-4'>{{ alertMsg }}</p>
    </v-alert>
    <v-alert
      v-model='showErrorAlert'
      class='rounded-xl pr-10'
      color='error'
      dark
      dense
      dismissible
      icon='mdi-information-outline'
      prominent
      transition='scale-transition'
      width='100%'
    >
      <p class='font-weight-bold pt-4'>{{ alertMsg }}</p>
    </v-alert>
    <v-card class='mx-4 pt-4'>
      <v-card-title class='ml-4 headline font-weight-bold'>
        Voters Guide
      </v-card-title>
      <v-data-table
        v-if='showPersonVotersGuide'
        v-model='selected'
        :footer-props='{
          disablePagination: loading,
          disableItemsPerPage: loading,
          itemsPerPageOptions: itemsPerPageOptionsPerson
        }'
        :headers='headersPerson'
        :items='personVotersGuides'
        :loading='loading'
        :options.sync='optionsPerson'
        :server-items-length='totalPersonVoterGuides'
        :items-per-page='10'
        checkboxColor='primary'
        class='pa-4'
        loading-text='Loading guides... Please wait'
        no-data-text='There are no voter guides available at the moment.'
        show-select
      >
        <template #top>
          <v-toolbar flat>
            <v-toolbar-title class='font-weight-medium text-capitalize'>
              {{ election.description }}
            </v-toolbar-title>
            <v-btn
              :href='exportRecommendationsUrl'
              class='text-capitalize font-weight-bold py-5 ml-5'
              color='primary'
              outlined
            >
              Export Recommendations
            </v-btn>
            <v-btn
              :href='exportPersonGuidesUrl'
              class='text-capitalize font-weight-bold py-5 mx-4'
              color='primary'
              outlined
            >
              Export Table
            </v-btn>
            <v-text-field
              v-model='searchCounty'
              class='mr-4'
              dense
              hide-details
              outlined
              placeholder='Search by County'
              prepend-inner-icon='mdi-magnify'
              single-line
            >
            </v-text-field>
            <v-text-field
              v-model='searchZone'
              class='mr-4'
              dense
              hide-details
              outlined
              placeholder='Search by Seat'
              prepend-inner-icon='mdi-magnify'
              single-line
            >
            </v-text-field>
            <div v-if='showPersonVotersGuide'>
              <v-btn
                :class="{ 'edit--toolbar': personalFilters.show }"
                class='text-capitalize font-weight-bold py-5 px-10'
                color='primary'
                outlined
                @click='personalFilters.show = !personalFilters.show'
              >
                Filters
              </v-btn>
            </div>
          </v-toolbar>
          <v-row v-if='selected.length' class='my-4 d-flex justify-space-between px-7'>
            <v-btn
              class='text-capitalize font-weight-bold'
              color='error'
              outlined
              @click='openDeleteVotersGuide'
            >
              <v-icon class='mr-2'> mdi-close-thick</v-icon>
              Delete
            </v-btn>
            <v-btn
              :loading='loadingPublish'
              class='text-capitalize font-weight-bold'
              color='primary'
              outlined
              @click='publishGuides'
            >
              <v-icon class='mr-2'> mdi-publish</v-icon>
              Publish
            </v-btn>
          </v-row>
          <div v-if='personalFilters.show' class='filters'>
            <p class='font-weight-bold'>Division</p>
            <v-radio-group
              v-model='personalFilters.division'
              row
              @change='onFilterDivisionChanged(personalFilters.division)'
            >
              <v-radio label='All' value='null'></v-radio>
              <v-radio label='Federal' value='Federal'></v-radio>
              <v-radio label='State' value='State'></v-radio>
              <v-radio label='County' value='County'></v-radio>
            </v-radio-group>
            <p class='font-weight-bold'>Publish Status</p>
            <v-radio-group v-model='personalFilters.published' row>
              <v-radio value='null' label='All'></v-radio>
              <v-radio :value=true label='Published'></v-radio>
              <v-radio :value=false label='Unpublished'></v-radio>
            </v-radio-group>
            <p class='font-weight-bold'>Position</p>
            <v-autocomplete
              v-model='personalFilters.zoneTypeId'
              :disabled='!personalFilters.zoneTypes.length'
              :items='personalFilters.zoneTypes'
              dense
              item-text='type'
              item-value='id'
              outlined
            >
              <template #selection={item}>
                {{ item.type }}
              </template>
              <template #item={item}>
                {{ item.type }}
              </template>
            </v-autocomplete>
            <v-btn
              class='text-capitalize font-weight-bold py-5 px-10'
              color='primary'
              outlined
              style='width: 100%;'
              @click='getPersonVotersGuide'
            >
              Apply
            </v-btn>
          </div>
        </template>
        <template #item.zoneType.type={item}>
          <span>{{ item.zone.seatType }}</span>
        </template>
        <template #item.published={item}>
          <span>{{ item.published ? 'Published' : 'Unpublished' }}</span>
        </template>
        <template #item.name={item}>
          <div v-html='item.name'></div>
        </template>
        <template #item.actions={item}>
          <v-btn
            :loading='loadingEditGuide && guideIndex === item.id'
            class='mr-2 my-1 pa-2 primary white--text rounded-sm'
            dense
            min-height='35'
            min-width='35'
            @click='openEdit(item)'
          >
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
          <v-btn
            class='mr-2 my-1 pa-2 error white--text rounded-sm'
            dense
            min-height='35'
            min-width='35'
            @click='openDeleteVotersGuide(item)'
          >
            <v-icon>mdi-close-thick</v-icon>
          </v-btn>
        </template>
      </v-data-table>
      <v-data-table
        v-else
        v-model='selected'
        :footer-props='{
          disablePagination: legislationLoading,
          disableItemsPerPage: legislationLoading,
          itemsPerPageOptions: itemsPerPageOptionsLegislation
        }'
        :headers='headersLegislation'
        :items='legislationVotersGuides'
        :loading='legislationLoading'
        :options.sync='optionsLegislation'
        :server-items-length='totalLegislationVoterGuides'
        :items-per-page='10'
        checkboxColor='primary'
        class='pa-4'
        loading-text='Loading guides... Please wait'
        no-data-text='There are no voter guides available at the moment.'
      >
        <template #top>
          <v-toolbar flat>
            <v-toolbar-title class='font-weight-medium text-capitalize'>
              {{ election.description }}
            </v-toolbar-title>
            <v-btn
              :href='exportRecommendationsUrl'
              class='text-capitalize font-weight-bold py-5 ml-5'
              color='primary'
              outlined
            >
              Export Recommendations
            </v-btn>
            <v-btn
              :href='exportLegislationGuidesUrl'
              class='text-capitalize font-weight-bold py-5 mx-4'
              color='primary'
              outlined
            >
              Export Table
            </v-btn>
            <v-btn
              v-if='!showPersonVotersGuide'
              class='text-capitalize font-weight-bold py-5 px-10'
              color='primary'
              outlined
              @click='openCreate'
            >
              <v-icon left> mdi-plus</v-icon>
              Add New
            </v-btn>
          </v-toolbar>
        </template>
        <template #item.zoneType.type={item}>
          <span>{{ item.zone.seatType }}</span>
        </template>
        <template #item.published={item}>
          <span>{{ item.published ? 'Published' : 'Unpublished' }}</span>
        </template>
        <template #item.name={item}>
          <div v-html='item.name'></div>
        </template>
        <template #item.actions={item}>
          <v-btn
            :loading='loadingEditGuide && guideIndex === item.id'
            class='mr-2 my-1 pa-2 primary white--text rounded-sm'
            dense
            min-height='35'
            min-width='35'
            @click='openEdit(item)'
          >
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
          <v-btn
            class='mr-2 my-1 pa-2 error white--text rounded-sm'
            dense
            min-height='35'
            min-width='35'
            @click='openDeleteVotersGuide(item)'
          >
            <v-icon>mdi-close-thick</v-icon>
          </v-btn>
        </template>
      </v-data-table>
      <div class=' text-center d-flex flex-row align-baseline justify-end mr-10 pt-4 pb-8'>
        <v-btn
          :color='showPersonVotersGuide ? "success" : "primary"'
          class='text-capitalize font-weight-bold py-5 ml-4'
          outlined
          @click='toggleDataTables'
        >
          {{ showPersonVotersGuide ? 'List Legislation Voter Guides' : 'List Person Voter Guides' }}
        </v-btn>
        <v-spacer></v-spacer>
      </div>
    </v-card>
    <v-dialog
      v-model='showDialog'
      max-width='1000px'
      persistent
      transition='dialog-bottom-transition'
    >
      <v-card>
        <v-card-title>
          <span v-if='creating' class='headline'>New Recommendation</span>
          <span v-if='editing' class='headline'>Edit Recommendation</span>
        </v-card-title>
        <v-tabs
          v-if="editing && guideType === 'Person'"
          v-model='tab'
          fixed-tabs
        >
          <v-tabs-slider color='primary'></v-tabs-slider>
          <v-tab
            v-for='tab in tabs'
            :key='tab'
          >
            {{ tab }}
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model='tab'>
          <v-tab-item
            v-for='(tab, index) in tabs'
            :key='tab'
            eager
          >
            <v-form>
              <v-card-text class='py-2 px-4'>
                <v-container class='pa-6'>
                  <div v-if="guideType === 'Person' && editedItems.length > 0">
                    <v-row class='mb-n5' md='4' sm='6'>
                      <v-col>
                        <p class='font-weight-bold'>Type</p>
                        <v-radio-group
                          v-model='editedItems[index].type'
                          :rules="[(v) => !!v || 'Field is required']"
                          row
                        >
                          <v-radio disabled label='Person' value='Person'></v-radio>
                          <v-radio disabled label='Legislation' value='Legislation'></v-radio>
                        </v-radio-group>
                      </v-col>
                    </v-row>
                    <v-row class='mb-n5' md='4' sm='6'>
                      <v-col>
                        <p class='font-weight-bold'>Division</p>
                        <v-radio-group
                          v-model='editedItems[index].scope'
                          row
                        >
                          <v-radio disabled label='Federal' value='FEDERAL'></v-radio>
                          <v-radio disabled label='State' value='STATE'></v-radio>
                          <v-radio disabled label='County' value='COUNTY'></v-radio>
                        </v-radio-group>
                      </v-col>
                    </v-row>
                    <v-row class='mb-n5' md='4' sm='6'>
                      <v-col>
                        <p class='font-weight-bold'>Position / Seat</p>
                        <v-autocomplete
                          v-model='editedItems[index].zoneType'
                          :items='divisions'
                          dense
                          disabled
                          outlined
                        >
                          <template #selection={item}>
                            {{ item.type }}
                          </template>
                          <template #item={item}>
                            {{ item.type }}
                          </template>
                        </v-autocomplete>
                      </v-col>
                    </v-row>
                    <v-row v-if='editing' class='mb-n5' md='4' sm='6'>
                      <v-col>
                        <p class='font-weight-bold'>Seat Type</p>
                        <v-autocomplete
                          v-model='editedItems[index].zone'
                          :items='codeValues'
                          :rules="[(v) => !!v || 'Field is required']"
                          dense
                          disabled
                          item-text='seatType'
                          item-value='id'
                          outlined
                          required
                        >
                          <template #selection={item}>
                            {{ item.seatType }}
                          </template>
                          <template #item={item}>
                            {{ item.seatType }}
                          </template>
                        </v-autocomplete>
                      </v-col>
                    </v-row>

                    <v-form ref='form' v-model='valid'>
                      <v-row v-if='editing' class='mb-n5' md='4' sm='6'>
                        <v-col>
                          <p class='font-weight-bold'>Name</p>
                          <v-text-field
                            v-model='editedItems[index].name'
                            :rules="[(v) => !!v || 'Field is required']"
                            dense
                            outlined
                            placeholder='i.e John Smith or the name of the legislation'
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row v-if='editing' class='mb-n5' md='4' sm='6'>
                        <v-col>
                          <p class='font-weight-bold'>Rationale</p>
                          <v-textarea
                            v-model='editedItems[index].viewpoint'
                            :rules="[(v) => !!v || 'Field is required']"
                            dense
                            outlined
                            placeholder="i.e We don't feel it beneficial or negative"
                          ></v-textarea>
                        </v-col>
                      </v-row>
                      <v-row v-if='editing' class='mb-n5' md='4' sm='6'>
                        <v-col>
                          <p class='font-weight-bold'>Description</p>
                          <v-textarea
                            v-model='editedItems[index].description'
                            outlined
                          ></v-textarea>
                        </v-col>
                      </v-row>
                      <v-row v-if='editing' class='mb-n5' md='4' sm='6'>
                        <v-col>
                          <p class='font-weight-bold'>Position</p>
                          <v-radio-group
                            v-model='editedItems[index].position'
                            :rules="[(v) => !!v || 'Field is required']"
                            class='justify-space-between'
                            row
                          >
                            <v-radio class='mr-10' label='For' value='For'></v-radio>
                            <v-radio
                              class='mr-10'
                              label='Neutral'
                              value='Neutral'
                            ></v-radio>
                            <v-radio
                              class='mr-10'
                              label='Against'
                              value='Against'
                            ></v-radio>
                          </v-radio-group>
                        </v-col>
                      </v-row>
                    </v-form>
                    <v-row v-if="editing && guideType === 'Person'" class='mb-n5' md='4' sm='6'>
                      <v-col>
                        <v-checkbox
                          v-model='editedItems.published'
                          color='primary'
                          label='Published'
                          class="d-inline-block"
                        >
                        </v-checkbox>
                      </v-col>
                    </v-row>
                  </div>
                  <div v-if="guideType === 'Legislation'">
                    <v-row class='mb-n5' md='4' sm='6'>
                      <v-col>
                        <p class='font-weight-bold'>Type</p>
                        <v-radio-group
                          v-model='editedItem.type'
                          :rules="[(v) => !!v || 'Field is required']"
                          row
                          @change='guideTypeSwitch(editedItem.type)'
                        >
                          <v-radio disabled label='Person' value='Person'></v-radio>
                          <v-radio disabled label='Legislation' value='Legislation'></v-radio>
                        </v-radio-group>
                      </v-col>
                    </v-row>
                    <v-row class='mb-n5' md='6' sm='6'>
                      <v-col md='6'>
                        <p class='font-weight-bold'>Location/Reach</p>
                        <v-select
                          v-model='editedItem.counties'
                          :items='divisions'
                          :rules="[(v) => !!v || 'Field is required']"
                          dense
                          item-text='name'
                          item-value='id'
                          label='Select a the location'
                          outlined
                          @change='onLocationSelect(editedItem.counties)'
                        >
                        </v-select>
                      </v-col>
                      <v-col md='6'>
                        <p class='font-weight-bold'>Zones</p>
                        <v-autocomplete
                          v-model='editedItem.zone'
                          :items='codeValues'
                          :rules="[(v) => !!v || 'Field is required']"
                          dense
                          item-text='seatType'
                          item-value='id'
                          label='Select a zone'
                          outlined
                          required
                        >
                          <template #selection={item}>
                            {{ item.seatType }}
                          </template>
                          <template #item={item}>
                            {{ item.seatType }}
                          </template>
                        </v-autocomplete>
                      </v-col>
                    </v-row>

                    <v-row class='mb-n5' md='4' sm='6'>
                      <v-col>
                        <p class='font-weight-bold'>Title</p>
                        <v-text-field
                          v-model='editedItem.title'
                          :rules="[(v) => !!v || 'Field is required']"
                          dense
                          outlined
                          placeholder='i.e John Smith or the name of the legislation'
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row class='mb-n5' md='4' sm='6'>
                      <v-col>
                        <p class='font-weight-bold'>Rationale</p>
                        <v-textarea
                          v-model='editedItem.viewpoint'
                          dense
                          outlined
                          :rules="[(v) => !!v || 'Field is required']"
                          placeholder="i.e We don't feel it beneficial or negative"
                        ></v-textarea>
                      </v-col>
                    </v-row>
                    <v-row class='mb-n5' md='4' sm='6'>
                      <v-col>
                        <p class='font-weight-bold'>Description</p>
                        <v-textarea
                          v-model='editedItem.description'
                          outlined
                        ></v-textarea>
                      </v-col>
                    </v-row>
                    <v-row class='mb-n5' md='4' sm='6'>
                      <v-col>
                        <p class='font-weight-bold'>Position</p>
                        <v-radio-group
                          v-model='editedItem.position'
                          :rules="[(v) => !!v || 'Field is required']"
                          class='justify-space-between'
                          row
                        >
                          <v-radio class='mr-10' label='For' value='For'></v-radio>
                          <v-radio class='mr-10' label='Neutral' value='Neutral'></v-radio>
                          <v-radio class='mr-10' label='Against' value='Against'></v-radio>
                        </v-radio-group>
                      </v-col>
                    </v-row>
                    <v-row v-if="guideType === 'Legislation'" class='mb-n5' md='4' sm='6'>
                      <v-col>
                        <v-checkbox
                          v-model='editedItem.published'
                          color='primary'
                          label='Published'
                          class="d-inline-block"
                        >
                        </v-checkbox>
                      </v-col>
                    </v-row>
                  </div>
                </v-container>
              </v-card-text>
            </v-form>
          </v-tab-item>
        </v-tabs-items>

        <v-card-actions class='d-flex direction-row justify-center'>
          <v-btn
            v-if="guideType === 'Person'"
            :loading='loadingClear'
            class='px-14 ma-4 font-weight-bold'
            color='primary'
            large
            outlined
            @click='clearDivisionFields'
          >
            Clear Division
          </v-btn>
          <v-btn
            class='px-14 ma-4 font-weight-bold'
            color='primary'
            large
            outlined
            @click='close'
          >
            Cancel
          </v-btn>
          <v-btn
            v-if="guideType === 'Person'"
            :loading='loadingSavePerson'
            class='px-14 ma-4 font-weight-bold white--text'
            color='primary'
            large
            @click='savePerson'
          >
            Submit
          </v-btn>
          <v-btn
            v-if="guideType === 'Legislation'"
            :loading='loadingSaveLegislation'
            class='px-14 ma-4 font-weight-bold white--text'
            color='primary'
            large
            @click='saveLegislation'
          >
            Submit
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model='dialogDelete' max-width='500px'>
      <v-card class='d-flex flex-column align-start pa-2'>
        <v-card-title class='text-center mb-5'>
          <v-icon class='mr-4 white--text' color='error' large left>
            mdi-alert
          </v-icon>
          Delete Voters Guide
        </v-card-title>
        <v-card-subtitle>
          <b>You are about to delete the following voter guides:</b>
        </v-card-subtitle>
        <p
          v-if='singleDelete && editedItem.type=== "Person" '
          class='ml-8 py-4 font-weight-bold secondary--text'
        >
          {{ editedItem.title }}
          {{ editedItem.name }}
        </p>
        <p
          v-if='editedItem.type=== "Legislation" '
          class='ml-8 py-4 font-weight-bold secondary--text'
        >
          {{ editedItem.title }}
          {{ editedItem.name }}
        </p>
        <v-card-actions
          v-if="editedItem.type === 'Person'"
          class='align-self-center'
        >
          <v-spacer></v-spacer>
          <v-btn
            class='px-14 mx-4 font-weight-bold white--text'
            color='primary'
            large
            outlined
            @click='dialogDelete = false'
          >
            Cancel
          </v-btn>
          <v-btn
            v-if='!singleDelete'
            :loading='loadingDeleteGuides'
            class='px-14 mx-4 font-weight-bold white--text'
            color='primary'
            large
            @click='deleteVotersGuide()'
          >
            Confirm
          </v-btn>
          <v-btn
            v-if='singleDelete'
            :loading='loadingDeleteGuides'
            class='px-14 mx-4 font-weight-bold white--text'
            color='primary'
            large
            @click='deleteVotersGuide(editedItem)'
          >
            Confirm
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
        <v-card-actions
          v-else
          class='align-self-center'
        >
          <v-spacer></v-spacer>
          <v-btn
            class='px-14 mx-4 font-weight-bold white--text'
            color='primary'
            large
            outlined
            @click='dialogDelete = false'
          >
            Cancel
          </v-btn>
          <v-btn
            :loading='loadingDeleteGuides'
            class='px-14 mx-4 font-weight-bold white--text'
            color='primary'
            large
            @click='deleteLegislationVotersGuide(editedItem.id)'
          >
            Confirm
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import votersGuideService from '../../services/votersGuide/index'
import { mapActions, mapGetters } from 'vuex'
import { HTTP } from '../../../http-common'

export default {
  data() {
    return {
      guide: {},
      parties: [],
      loadingSavePerson: false,
      loadingSaveLegislation: false,
      loadingClear: false,
      loadingDeleteGuides: false,
      alertMsg: '',
      showAlert: false,
      showErrorAlert: false,
      loadingPublish: false,
      searchCounty: '',
      searchZone: '',
      itemsPerPageOptionsPerson: [10, 50, 100, 250, -1],
      itemsPerPageOptionsLegislation: [10, 50, 100, 250, -1],
      searchTimer: null,
      optionsPerson: {},
      optionsLegislation: {},
      tab: 0,
      tabs: ['Non Partisan', 'Democrat', 'Independent', 'Republican'],
      guideIndex: -1,
      loadingEditGuide: false,
      dialogDelete: false,
      loading: true,
      legislationLoading: false,
      showPersonVotersGuide: true,
      valid: true,
      showDialog: false,
      creating: false,
      editing: false,
      singleDelete: true,
      guideType: '',
      codeValues: [],
      divisions: [],
      selected: [],
      editedItems: [],
      editedItem: {},
      defaultItem: {},
      legislationVotersGuides: [],
      personVotersGuides: [],
      totalPersonVoterGuides: 0,
      totalLegislationVoterGuides: 0,
      headers: [],
      headersPerson: [
        {
          text: 'Type',
          align: 'start',
          sortable: false,
          value: 'type'
        },
        {
          text: 'Name',
          align: 'start',
          sortable: true,
          value: 'name'
        },
        {
          text: 'County',
          align: 'start',
          sortable: true,
          value: 'county'
        },
        {
          text: 'District Type',
          align: 'start',
          sortable: true,
          value: 'distType'
        },
        {
          text: 'Seat',
          align: 'start',
          sortable: true,
          value: 'zoneType.type'
        },
        {
          text: 'Publish Status',
          align: 'start',
          sortable: true,
          value: 'published'
        },
        {
          text: 'Actions',
          value: 'actions',
          sortable: false,
          width: '15%'
        }
      ],
      headersLegislation: [
        {
          text: 'Type',
          align: 'start',
          sortable: false,
          value: 'type'
        },
        {
          text: 'Title',
          align: 'start',
          sortable: false,
          value: 'title'
        },
        {
          text: 'County',
          align: 'start',
          sortable: false,
          value: 'county'
        },
        {
          text: 'District Type',
          align: 'start',
          sortable: false,
          value: 'distType'
        },
        {
          text: 'Seat',
          align: 'start',
          sortable: false,
          value: 'zoneType.type'
        },
        {
          text: 'Publish Status',
          align: 'start',
          sortable: false,
          value: 'published'
        },
        {
          text: 'Actions',
          value: 'actions',
          sortable: false,
          width: '15%'
        }
      ],
      seatItems: [],
      personalFilters: {
        show: false,
        division: 'null',
        published: 'null',
        zoneTypeId: null,
        zoneTypes: []
      }
    }
  },
  watch: {
    optionsPerson: {
      async handler() {
        await this.getPersonVotersGuide()
      },
      deep: true
    },
    optionsLegislation: {
      async handler() {
        await this.getLegislationVotersGuide()
      },
      deep: true
    },
    searchCounty() {
      clearTimeout(this.searchTimer)
      this.searchTimer = setTimeout(async () => await this.getPersonVotersGuide(), 1000)
    },
    searchZone() {
      clearTimeout(this.searchTimer)
      this.searchTimer = setTimeout(async () => await this.getPersonVotersGuide(), 1000)
    }
  },
  computed: {
    ...mapGetters(['election']),
    exportRecommendationsUrl() {
      return `${HTTP.defaults.baseURL}export-voter-recommendations/${this.election.id}`
    },
    exportPersonGuidesUrl() {
      return `${HTTP.defaults.baseURL}export-person-voter-guide/${this.election.id}`
    },
    exportLegislationGuidesUrl() {
      return `${HTTP.defaults.baseURL}export-legislation-voter-guide/${this.election.id}`
    }
  },
  methods: {
    ...mapActions('votersGuideStore', ['clearDivision', 'deleteLegislation']),
    async clearDivisionFields() {
      this.loadingClear = true
      const data = {
        id: this.editedItems[this.tab].id,
        published: this.guide.published
      }
      try {
        if (this.parties[this.tab]?.name) {
          await this.clearDivision(data)
          await this.getPersonVotersGuide()
        } else {
          this.$refs.form[this.tab].reset()
        }
        await this.collectEditedItems(this.editedItems[this.tab])
        this.$refs.form[this.tab].resetValidation()
      } catch (e) {
        this.showAlertMsg('error')
      }
      this.loadingClear = false
    },
    async publishGuides() {
      this.loadingPublish = true
      const selectedForPublish = this.selected.filter(el => el.name && !el.published).map(el => el.id)
      try {
        if (selectedForPublish.length) {
          await votersGuideService.publishGuides(selectedForPublish)
          await this.getPersonVotersGuide()
          this.showAlertMsg('publish')
        } else {
          this.showAlertMsg('error')
        }
        this.selected = []
      } catch (e) {
        this.showAlertMsg('error')
      }
      this.loadingPublish = false
    },
    async openEdit(item) {
      this.guide = item
      this.guideIndex = item.id
      this.loadingEditGuide = true
      this.editing = true
      this.editedItems = []
      if (item.type === 'Legislation') {
        await Promise.allSettled([
          this.guideTypeSwitch('Legislation'),
          this.getCodeValues(item.counties.id)
        ])
        this.editedItem = item
      } else {
        this.tab = 0
        await Promise.allSettled([
          this.guideTypeSwitch('Person'),
          this.collectEditedItems(item),
          this.getDivisionType(item.scope),
          this.getCodeValues(item.counties.id)
        ])
      }
      this.editedItems.initialPublishedStatus = item.published
      this.defaultItem = item
      this.showDialog = true
      this.loadingEditGuide = false
    },
    async collectEditedItems(item) {
      this.parties = await votersGuideService.getGuidesDetail(this.election.id, item.zone.id)
      this.editedItems.published = item.published //published should be the same for all parties in one guide
      this.parties.forEach((el, i) => {
        if (el) {
          this.editedItems[i] = {
            ...el,
            scope: item.scope
          }
        } else {
          this.editedItems[i] = {
            ...item,
            name: '',
            description: '',
            viewpoint: '',
            position: ''
          }
        }
      })
    },
    async openCreate() {
      await this.guideTypeSwitch('Legislation')
      this.editedItem.type = 'Legislation'
      this.creating = true
      this.showDialog = true
    },
    close() {
      this.showDialog = false
      this.creating = false
      this.editing = false
      this.dialogDelete = false
      this.editedItem = {}
      this.seatItems = []
      this.resetForm()
    },
    async guideTypeSwitch(guideType) {
      this.guideType = guideType
      this.divisions = []
      this.codeValues = []
      this.seatItems = []
      this.editedItem.scope = ''
      this.editedItem.zoneType = ''
      this.editedItem.zones = ''
      this.editedItem.name = ''
      this.editedItem.title = ''
      this.editedItem.description = ''
      this.editedItem.viewpoint = ''
      this.editedItems.forEach(el => {
        el.name = ''
        el.description = ''
        el.viewpoint = ''
      })
      if (guideType === 'Legislation') {
        await this.getDivisionType('Legislation')
      }
    },
    // async populateCodeValues(item) {
    //   this.seatItems = []
    //   this.seatObject = []
    //   await this.getCodeValues(item)
    //   if (this.creating) {
    //     this.codeValues.forEach((code) => {
    //       let seatObject = {
    //         type: 'Person',
    //         division: this.editedItem.scope,
    //         seatType: code.seatType,
    //         id: code.id,
    //         name: this.editedItem.name,
    //         viewpoint: this.editedItem.viewpoint,
    //         description: this.editedItem.description,
    //         position: 'For',
    //         electionId: this.election.id
    //       }
    //       this.seatItems.push(seatObject)
    //     })
    //   }
    // },
    openDeleteVotersGuide(item) {
      if (!item) {
        this.singleDelete = false
        this.editedItem = {}
        this.dialogDelete = true
      } else {
        this.dialogDelete = true
        this.singleDelete = true
        this.editedItem = item
      }
    },
    // deleteSeat(item) {
    //   const index = this.seatItems.indexOf(item)
    //   this.seatItems.splice(index, 1)
    // },
    onLocationSelect(item) {
      this.getCodeValues(item)
    },
    resetForm() {
      this.$refs.form.forEach(el => el.reset())
    },
    async savePerson() {
      const valid = this.$refs.form.some(el => el.validate())
      if (valid) {
        this.$refs.form.forEach(el => el.resetValidation())
        if (this.editing) {
          this.loadingSavePerson = true
          await this.editVotersPersonGuide()
          this.showAlertMsg('edit')
        }
        this.close()
        await this.getPersonVotersGuide()
        this.loadingSavePerson = false
      }
    },
    async saveLegislation() {
      this.loadingSaveLegislation = true
      if (this.creating) {
        await this.addVotersLegislationGuide()
        this.showAlertMsg('create')
      }
      if (this.editing) {
        await this.editVotersLegislationGuide()
        this.showAlertMsg('edit')
      }
      await this.getLegislationVotersGuide()
      this.loadingSaveLegislation = false
      this.close()
    },
    async toggleDataTables() {
      this.showPersonVotersGuide = !this.showPersonVotersGuide
      this.showPersonVotersGuide ? await this.getPersonVotersGuide() : await this.getLegislationVotersGuide()
      this.selected = []
    },
    showAlertMsg(type) {
      if (type !== 'error') this.showAlert = true
      switch (type) {
        case 'create':
          this.alertMsg = 'Voter Guide created successfully.'
          break
        case 'delete':
          this.alertMsg = 'Voter Guide deleted successfully.'
          break
        case 'edit':
          this.alertMsg = 'Voter Guide edited successfully.'
          break
        case 'publish':
          this.alertMsg = 'Voters Guides published successfully.'
          break
        case 'error':
          this.alertMsg = 'Something went wrong.'
          this.showErrorAlert = true
          break
      }
      this.hideAlertMsg()
    },
    hideAlertMsg() {
      window.setTimeout(() => {
        this.showAlert = false
        this.showErrorAlert = false
      }, 5000)
    },

    async addVotersLegislationGuide() {
      if (this.editedItem.published === undefined)
        this.editedItem.published = false
      const data = {
        electionId: this.election.id,
        type: this.editedItem.type,
        zoneId: this.editedItem.zone,
        title: this.editedItem.title,
        description: this.editedItem.description,
        position: this.editedItem.position,
        viewpoint: this.editedItem.viewpoint,
        published: this.editedItem.published
      }
      try {
        await votersGuideService.addVoterLegislationGuide(data) // {electionId,type,zoneId,title,description,position,viewpoint,published}
      } catch (err) {
        this.showAlertMsg('error')
      }
    },
    async editVotersLegislationGuide() {
      let zoneId
      typeof this.editedItem.zone === 'object'
        ? zoneId = this.editedItem.zone.id
        : zoneId = this.editedItem.zone
      const data = {
        id: this.editedItem.id,
        zoneId: zoneId,
        type: this.editedItem.type,
        title: this.editedItem.title,
        description: this.editedItem.description,
        viewpoint: this.editedItem.viewpoint,
        position: this.editedItem.position,
        published: this.editedItem.published
      }
      try {
        await votersGuideService.editVoterLegislationGuide(data, this.editedItem.id)
      } catch (err) {
        this.showAlertMsg('error')
      }
    },
    async editVotersPersonGuide() {
      const data = []
      let id = null
      for (let i = 0; i < this.editedItems.length; i++) {
        if (this.$refs.form[i] && !this.$refs.form[i].validate()) continue
        data.push({
          id: this.editedItems[i].id,
          electionId: this.election.id,
          type: this.editedItems[i].type,
          zoneId: this.editedItems[i].zone.id,
          name: this.editedItems[i].name,
          description: this.editedItems[i].description,
          viewpoint: this.editedItems[i].viewpoint,
          position: this.editedItems[i].position,
          zoneType: this.editedItems[i].zoneType.id,
          party: this.tabs[i],
          published: this.editedItems.published,
          publishedStatusChanged: this.editedItems.initialPublishedStatus !== this.editedItems.published
        })
        id = this.editedItems[i].id
      }
      try {
        await votersGuideService.editVotersGuide(data, id)
      } catch (err) {
        this.showAlertMsg('error')
      }
    },
    async getPersonVotersGuide() {
      this.loading = true
      this.personVotersGuides = []
      try {
        const {sortBy, sortDesc, page, itemsPerPage} = this.optionsPerson
        const personFilters = {
          sortBy: sortBy[0],
          sortDesc: sortDesc?.length && sortDesc[0],
          page,
          itemsPerPage,
          published: this.personalFilters.published,
          division: this.personalFilters.division,
          position: this.personalFilters.zoneTypeId,
          search_county: this.searchCounty ? this.searchCounty : undefined,
          search_zone: this.searchZone ? this.searchZone : undefined
        }
        const {data, meta: {total}} = await votersGuideService.getVotersGuides(personFilters, this.election.id)
        this.personVotersGuides = data
        this.totalPersonVoterGuides = total
      } catch (e) {
        this.showAlertMsg('error')
      }
      this.loading = false
    },
    async getLegislationVotersGuide() {
      this.legislationLoading = true
      this.legislationVotersGuides = []
      try {
        const {page, itemsPerPage} = this.optionsLegislation
        const {data, meta: {total}} = await votersGuideService.getVoterGuidesLegislation({
          page,
          itemsPerPage
        }, this.election.id)
        this.legislationVotersGuides = data
        this.totalLegislationVoterGuides = total
      } catch (e) {
        this.showAlertMsg('error')
      }
      this.legislationLoading = false
    },
    async deleteVotersGuide(item) {
      this.loadingDeleteGuides = true
      const selectedPublishedIds = []
      const selectedDraftsIds = []
      if (this.selected.length) {
        this.selected.forEach(el => el.published ? selectedPublishedIds.push(el.id) : selectedDraftsIds.push(el.id))
      } else {
        item.published ? selectedPublishedIds.push(item.id) : selectedDraftsIds.push(item.id)
      }
      try {
        if (selectedPublishedIds.length) await votersGuideService.deleteVotersGuide(selectedPublishedIds)
        if (selectedDraftsIds.length) await votersGuideService.deleteVotersGuideDrafts(selectedDraftsIds)
        this.selected = []
        this.showAlertMsg('delete')
        await this.getPersonVotersGuide()
      } catch (e) {
        this.showAlertMsg('error')
      }
      this.loadingDeleteGuides = false
      this.dialogDelete = false
    },
    async deleteLegislationVotersGuide(id) {
      this.loadingDeleteGuides = true
      try {
        await this.deleteLegislation(id)
        this.showAlertMsg('delete')
        await this.getLegislationVotersGuide()
      } catch (e) {
        this.showAlertMsg('error')
      }
      this.loadingDeleteGuides = false
      this.dialogDelete = false
    },
    async getDivisionType(scope) {
      try {
        this.divisions = await votersGuideService.getDivisionType(scope)
      } catch (err) {
        console.log(err)
      }
    },
    async getCodeValues(item) {
      this.codeValues = []
      try {
        this.codeValues = await votersGuideService.getCodeValue(item)
      } catch (err) {
        console.log(err)
      }
    },
    async onFilterDivisionChanged(division) {
      this.personalFilters.zoneTypeId = null
      try {
        this.personalFilters.zoneTypes = await votersGuideService.getDivisionType(division)
      } catch (err) {
        console.log(err)
      }
    }
  }
}
</script>
